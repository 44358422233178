// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import { sendEventVerticalPage } from "../../services/analytics.services";
import { serverResponse } from "../../util/fakeServer";
import ManagerFactory from "../../managers/ManagerFactory";
const manager = ManagerFactory.getManager();
const ScrollListener = ({ children }) => {
  const [initial] = useState(serverResponse.leaflet.index ? 0 : 1);
  const scrollable = document.querySelector(".Carousel");
  const slide = document.querySelector("#slide_1");
  const currentSlide = () => {
    if (scrollable && slide) {
      const getCurrentSS = sessionStorage.getItem("lastView") || initial;
      const approximation = serverResponse.leaflet.index ? 0 : 1;
      const indexCurrentSlide =
        Math.ceil(scrollable.scrollTop / slide.clientHeight) + approximation >=
        serverResponse.leaflet.pages.length
          ? serverResponse.leaflet.pages.length
          : Math.ceil(scrollable.scrollTop / slide.clientHeight) +
            approximation;
      // const arrForAnalytics = `${getCurrentSS}-${indexCurrentSlide}`;
      sessionStorage.setItem("lastView", indexCurrentSlide);
      if (
        Number(getCurrentSS) !== indexCurrentSlide &&
        Number(getCurrentSS) !== sessionStorage.getItem("lastView")
      ) {
        //API
        const data = {
          id_cliente: serverResponse.config.client_id,
          id_insegna: serverResponse.config.signboard_id,
          id_utente: serverResponse.config.release_id,
          id_volantino: serverResponse.leaflet.id
        };
        if (serverResponse.config.ga_active && manager.sendAnalitics()) {
          // alert(sessionStorage.getItem("lastView"));
          sendEventVerticalPage({
            ...data,
            pages: sessionStorage.getItem("lastView")
          }).catch((err) => console.log(err));
        }
      }
      return indexCurrentSlide;
    }
    return false;
  };
  useEffect(() => {
    var timer = null;
    const handleScroll = () => {
      if (timer !== null) {
        clearTimeout(timer);
      }
      timer = setTimeout(function() {
        currentSlide();
      }, 1500);
    };
    if (scrollable) scrollable.addEventListener("scroll", handleScroll, false);
    return () => {
      if (scrollable) scrollable.removeEventListener("scroll", handleScroll);
    };
  }, [scrollable]);
  return <>{children}</>;
};
export default ScrollListener;
