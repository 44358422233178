import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import React from "react";
import { withRouter } from "react-router-dom";
import "./loading.css";
import { serverResponse } from "../util/fakeServer";
import { useLocation } from "react-router-dom";
import { allowPath } from "../helper/utility";

const Loading = (props) => {
  const location = useLocation();

  // if (
  //   !serverResponse.config.only_logged ||
  //   (props.authJWT.isAuthenticated && !props.authJWT.loadingProfile)
  // ) {
  //   // if (serverResponse.config.allow_login) {
  //   //   return <Redirect to={location.pathname} />;
  //   // }
  //   return <Redirect to="/leaflet" />;
  // }

  if (
    serverResponse.config.only_logged &&
    !props.authJWT.isAuthenticated &&
    props.authJWT.loadingProfile &&
    !allowPath.includes(location.pathname)
  ) {
    return <Redirect to="/login" />;
  }

  if (serverResponse.config.allow_login && location.pathname !== "/") {
    return <Redirect to={location.pathname} />;
  }

  return <Redirect to="/leaflet" />;
};

const mapStateToProps = (state) => ({
  authJWT: state.authJWT
});

export default withRouter(connect(mapStateToProps)(Loading));
