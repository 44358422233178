/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import "./login.css";
import { connect } from "react-redux";
import { loginJWT, signupJWT } from "../redux/authJWT/action";
import { serverResponse } from "../util/fakeServer";
import { useHistory, useLocation } from "react-router-dom";
import LoadingPages from "./Loading.pages";
import * as services from "../services/resetPassword.services";
import { language } from "../languageProvider/language/language";
import { defaultLanguage } from "../helper/utility";
import { BREAKPOINT, interattivoLogo } from "../helper/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { startLoadingJWT, endLoadingJWT } from "../redux/authJWT/action";
import useCurrentWidth from "../hooks/widthHook";

function FormTroilo(props) {
  const { passwordProtected } = serverResponse.config;
  const [option, setOption] = useState(1);
  const location = useLocation();

  const width = useCurrentWidth();

  const history = useHistory();

  const [errorReset, setErrorReset] = useState(null);
  const [feedMessage, setFeedMessage] = useState(false);
  const [form, setForm] = useState({
    email: passwordProtected ? "volantino@interattivo.it" : "",
    password: "",
    name: "",
    surname: "",
    address: "",
    phone: "",
    sdi: "",
    comune: "",
    provincia: "",
    cap: "",
    ragione_sociale: "",
    partita_iva: "",
    extra1: ""
  });
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    switch (location.pathname) {
      case "/register":
        setOption(2);
        break;
      case "/reset-psw":
        setOption(3);
        break;

      default:
        setOption(1);
    }
  }, []);

  const onChange = ({ name, value }) => {
    setForm({ ...form, [name]: value });
  };

  const resetAll = () => {
    setErrorReset(null);
    setFeedMessage(null);
  };
  const handleOnPress = () => {
    setErrorReset(null);
    if (!feedMessage) {
      if (option === 1) {
        props.loginJWT(form, history);
      }
      if (option === 2) {
        props.signupJWT(form);
      }
      if (option === 3) {
        const dataReset = {
          email: form.email,
          id_signboard: serverResponse.config.signboard_id,
          url: `${window.location.href.replace("reset-psw", "")}set-psw`,
          home: `${window.location.href.replace("reset-psw", "")}set-psw`
        };
        props.startLoadingJWT();
        services
          .resetPassword(dataReset)
          .then((res) => {
            switch (res.data.status) {
              case 200:
                setFeedMessage(language[defaultLanguage].email_successo);
                break;
              case 403:
                setErrorReset(language[defaultLanguage].email_non_presente);
                break;
              default:
                setErrorReset(language[defaultLanguage].errore_invio_mail);
                break;
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => props.endLoadingJWT());
      }
    }
  };

  const handleKeyPress = (e) => {};

  useEffect(() => {
    document.addEventListener("keyup", handleKeyPress);

    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, []);

  if (props.authJWT.loadingProfile) {
    return <LoadingPages />;
  }

  if (
    !serverResponse.config.allow_login ||
    (props.authJWT.isAuthenticated && !props.authJWT.loadingProfile)
  ) {
    if (location.state && location.state.referrer)
      return <Redirect to={`${location.state.referrer}`} />;
    else return <Redirect to={`/leaflet`} />;
  }

  return (
    <div
      className="global-wrapper"
      style={{ flexDirection: width <= BREAKPOINT.lg ? "column" : "row" }}
    >
      <div
        className="main-container"
        style={{
          padding: "10px",
          overflowY: width >= BREAKPOINT.lg ? "hidden" : "scroll",
          paddingBottom: "1rem"
        }}
      >
        <img
          style={{ width: "75px" }}
          className="img-fluid"
          src={serverResponse.config.logo_full}
          alt="logo cliente"
        />
        <header>
          <div
            className={
              "header-headings " +
              (option === 1 ? "sign-in" : option === 2 ? "sign-up" : "forgot")
            }
          >
            <span>{language[defaultLanguage].effettua_login}</span>
            <span>{language[defaultLanguage].crea_account}</span>
            <span>{language[defaultLanguage].resetta_psw}</span>
          </div>
        </header>
        {!props.authJWT.success_signup && (
          <ul className="options" style={{ listStyle: "none", padding: 0 }}>
            <li
              className={option === 1 ? "active" : ""}
              onClick={() => {
                resetAll();
                setOption(1);
                history.replace("/login");
              }}
            >
              {language[defaultLanguage].login}
            </li>
            {!passwordProtected && (
              <li
                className={option === 2 ? "active" : ""}
                onClick={() => {
                  resetAll();
                  setOption(2);
                  history.replace("/register");
                }}
              >
                {language[defaultLanguage].registrati}
              </li>
            )}
            {!passwordProtected && (
              <li
                className={option === 3 ? "active" : ""}
                onClick={() => {
                  resetAll();
                  setOption(3);
                  history.replace("/reset-psw");
                }}
              >
                {language[defaultLanguage].rec_psw}
              </li>
            )}
          </ul>
        )}

        <form
          className="account-form"
          onSubmit={(evt) => {
            evt.preventDefault();
            handleOnPress();
          }}
        >
          {!props.authJWT.success_signup && (
            <div
              style={{
                position: "relative",
                overflowY: option === 2 ? "scroll" : "hidden",
                maxHeight: option === 2 ? "380px" : "125px"
              }}
              className={
                "account-form-fields " +
                (option === 1 ? "sign-in" : option === 2 ? "sign-up" : "forgot")
              }
            >
              {/* EMAIL & PASSWORD */}
              <div>
                {!passwordProtected && (
                  <input
                    className="mr-1"
                    style={{ width: "98%" }}
                    value={form.email || ""}
                    id="email"
                    name="email"
                    type="email"
                    placeholder={`${language[defaultLanguage].email}*`}
                    required
                    onChange={(e) => {
                      onChange({ name: "email", value: e.target.value });
                    }}
                  />
                )}
                <input
                  style={{ width: "98%" }}
                  value={form.password || ""}
                  id="password"
                  name="password"
                  type={hidden ? "password" : "text"}
                  placeholder={`${language[defaultLanguage].password}*`}
                  required={option === 1 || option === 2 ? true : false}
                  disabled={option === 3 ? true : false}
                  onChange={(e) => {
                    onChange({ name: "password", value: e.target.value });
                  }}
                />
              </div>

              {/* NAME & SURNAME */}
              <div>
                {!passwordProtected && (
                  <input
                    style={{ width: "98%" }}
                    minLength={2}
                    value={form.name || ""}
                    name="name"
                    id="name"
                    type="text"
                    placeholder={`${language[defaultLanguage].nome}*`}
                    required={option === 2 ? true : false}
                    disabled={option === 1 || option === 3 ? true : false}
                    onChange={(e) => {
                      onChange({ name: "name", value: e.target.value });
                    }}
                  />
                )}
                {!passwordProtected && (
                  <input
                    className="mr-1"
                    style={{ width: "98%" }}
                    minLength={2}
                    value={form.surname || ""}
                    id="surname"
                    name="surname"
                    type="text"
                    placeholder={`${language[defaultLanguage].cognome}*`}
                    required={option === 2 ? true : false}
                    disabled={option === 1 || option === 3 ? true : false}
                    onChange={(e) => {
                      onChange({ name: "surname", value: e.target.value });
                    }}
                  />
                )}
              </div>

              {/* PEC & SDI */}
              <div>
                {!passwordProtected && (
                  <input
                    style={{ width: "98%" }}
                    minLength={2}
                    value={form.pec || ""}
                    id="pec"
                    name="pec"
                    type="email"
                    placeholder={`${language[defaultLanguage].pec}*`}
                    required={option === 2 ? true : false}
                    disabled={option === 1 || option === 3 ? true : false}
                    onChange={(e) => {
                      onChange({ name: "pec", value: e.target.value });
                    }}
                  />
                )}
                {!passwordProtected && (
                  <input
                    style={{ width: "98%" }}
                    minLength={2}
                    value={form.sdi || ""}
                    id="sdi"
                    name="sdi"
                    type="text"
                    placeholder={`${language[defaultLanguage].sdi}*`}
                    required={option === 2 ? true : false}
                    disabled={option === 1 || option === 3 ? true : false}
                    onChange={(e) => {
                      onChange({ name: "sdi", value: e.target.value });
                    }}
                  />
                )}
              </div>

              {/* COMUNE & PROVINCIA */}
              <div>
                {!passwordProtected && (
                  <input
                    className="mr-1"
                    style={{ width: "98%" }}
                    minLength={2}
                    value={form.comune || ""}
                    id="comune"
                    name="comune"
                    type="text"
                    placeholder={`${language[defaultLanguage].comune}*`}
                    required={option === 2 ? true : false}
                    disabled={option === 1 || option === 3 ? true : false}
                    onChange={(e) => {
                      onChange({ name: "comune", value: e.target.value });
                    }}
                  />
                )}
                {!passwordProtected && (
                  <input
                    style={{ width: "98%" }}
                    minLength={2}
                    value={form.provincia || ""}
                    id="provincia"
                    name="provincia"
                    type="text"
                    placeholder={`${language[defaultLanguage].provincia}*`}
                    required={option === 2 ? true : false}
                    disabled={option === 1 || option === 3 ? true : false}
                    onChange={(e) => {
                      onChange({ name: "provincia", value: e.target.value });
                    }}
                  />
                )}
              </div>

              {/* CAP & TELEFONO */}
              <div>
                {!passwordProtected && (
                  <input
                    className="mr-1"
                    style={{ width: "98%" }}
                    minLength={2}
                    value={form.cap || ""}
                    id="cap"
                    name="cap"
                    type="text"
                    placeholder={`${language[defaultLanguage].cap}*`}
                    required={option === 2 ? true : false}
                    disabled={option === 1 || option === 3 ? true : false}
                    onChange={(e) => {
                      onChange({ name: "cap", value: e.target.value });
                    }}
                  />
                )}
                {!passwordProtected && (
                  <input
                    style={{ width: "98%" }}
                    minLength={2}
                    value={form.phone || ""}
                    id="phone"
                    name="phone"
                    type="text"
                    placeholder={`${language[defaultLanguage].phone}*`}
                    required={option === 2 ? true : false}
                    disabled={option === 1 || option === 3 ? true : false}
                    onChange={(e) => {
                      onChange({ name: "phone", value: e.target.value });
                    }}
                  />
                )}
              </div>

              {/* INDIRIZZO */}

              <div>
                {!passwordProtected && (
                  <input
                    className="mr-1"
                    style={{ width: "98%" }}
                    minLength={2}
                    value={form.address || ""}
                    id="address"
                    name="address"
                    type="text"
                    placeholder={`${language[defaultLanguage].address}*`}
                    required={option === 2 ? true : false}
                    disabled={option === 1 || option === 3 ? true : false}
                    onChange={(e) => {
                      onChange({ name: "address", value: e.target.value });
                    }}
                  />
                )}
              </div>

              {/* RAGIONE SOCIALE & PARTITA IVA */}
              <div>
                {!passwordProtected && (
                  <input
                    className="mr-1"
                    style={{ width: "98%" }}
                    minLength={2}
                    value={form.ragione_sociale || ""}
                    id="ragione_sociale"
                    name="ragione_sociale"
                    type="text"
                    placeholder={`${language[defaultLanguage].ragione_sociale}*`}
                    required={option === 2 ? true : false}
                    disabled={option === 1 || option === 3 ? true : false}
                    onChange={(e) => {
                      onChange({
                        name: "ragione_sociale",
                        value: e.target.value
                      });
                    }}
                  />
                )}
                {!passwordProtected && (
                  <input
                    style={{ width: "98%" }}
                    minLength={2}
                    value={form.partita_iva || ""}
                    id="partita_iva"
                    name="partita_iva"
                    type="text"
                    placeholder={`${language[defaultLanguage].partita_iva}*`}
                    required={option === 2 ? true : false}
                    disabled={option === 1 || option === 3 ? true : false}
                    onChange={(e) => {
                      onChange({ name: "partita_iva", value: e.target.value });
                    }}
                  />
                )}
              </div>

              <div>
                {!passwordProtected && (
                  <input
                    className="mr-1"
                    style={{ width: "98%" }}
                    minLength={2}
                    value={form.extra1 || ""}
                    id="extra1"
                    name="extra1"
                    type="text"
                    placeholder={`${language[defaultLanguage].extra1}*`}
                    required={option === 2 ? true : false}
                    disabled={option === 1 || option === 3 ? true : false}
                    onChange={(e) => {
                      onChange({ name: "extra1", value: e.target.value });
                    }}
                  />
                )}
              </div>
            </div>
          )}

          {!feedMessage && !props.authJWT.feedbackMessage && (
            <button className="btn-submit-form mt-3" type="submit">
              {option === 1
                ? language[defaultLanguage].login
                : option === 2
                ? language[defaultLanguage].registrati
                : language[defaultLanguage].resetta_psw}
              {props.authJWT.loading && (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}

              {!!errorReset && <div style={{ color: "red" }}>{errorReset}</div>}
            </button>
          )}
          {!!props.authJWT.error && (
            <div style={{ color: "red" }}>
              <p>{props.authJWT.error}</p>
            </div>
          )}
          {feedMessage && (
            <p style={{ color: "#0008", width: "320px", textAlign: "center" }}>
              <>
                {feedMessage}
                <FontAwesomeIcon style={{ color: "green" }} icon={faCheck} />
              </>
            </p>
          )}
          {props.authJWT.feedbackMessage && (
            <p style={{ color: "#0008", width: "320px", textAlign: "center" }}>
              <>
                {props.authJWT.feedbackMessage}
                <FontAwesomeIcon style={{ color: "green" }} icon={faCheck} />
              </>
            </p>
          )}
        </form>
      </div>

      <div
        style={{ position: width <= BREAKPOINT.lg ? "static" : "absolute" }}
        className="login_footer_troilo"
      >
        <span
          style={{
            fontSize: "0.7em",
            marginRight: "5px"
          }}
        >
          powered by
        </span>
        <img
          style={{
            width: "200px"
          }}
          className="img-fluid"
          src={interattivoLogo}
          alt="logo creator"
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authJWT: state.authJWT
  };
};

export default connect(mapStateToProps, {
  loginJWT,
  signupJWT,
  startLoadingJWT,
  endLoadingJWT
})(FormTroilo);
