// eslint-disable-next-line no-unused-vars
import React from "react";
import { serverResponse } from "../util/fakeServer";
import DefaultManager from "./Default.manager";

const typeFlyer = {
  leaflet: "logo_interattivo_new.svg",
  menu: "logo_menu_interattivo.svg",
  catalog: "logo_catalog_interattivo.svg"
};

// const iconDemoType = {
//   leaflet: "logo_new.png",
//   menu: "logo_new.png",
//   catalog: "logo_new.png"
// };

// const iconLogoNegativeType = {
//   leaflet: "logo_new.png",
//   menu: "logo_new.png",
//   catalog: "logo_new.png"
// };

const interattivoTypeLogo = typeFlyer[serverResponse.config.type];
// const iconDemoTypeLogo = iconDemoType[serverResponse.config.type];
// const typeLogoNegative = iconLogoNegativeType[serverResponse.config.type];

export const interattivoLogo = require(`assets/images/${interattivoTypeLogo}`);

class ErgonManager extends DefaultManager {
  signboardId;

  constructor(signboardId) {
    super(signboardId);
    this.signboardId = signboardId;
  }

  getAnalyticsId() {
    switch (this.signboardId) {
      case 46:
        return "G-FE092Q4D3B";

      default:
        return serverResponse.config.ga_tracking_id;
    }
  }

  getBaseWidth() {
    return "0rem";
  }

  showList() {
    return false;
  }

  getAppLogo() {
    return interattivoLogo;
  }

  getAppLogoSize() {
    return "75px";
  }

  showButtonFloatingBar() {
    return true;
  }
  getConsentBanner() {
    return <></>;
  }

  sendAnalitics() {
    return false;
  }
}

export default ErgonManager;
