import * as React from "react"

const SchermoIntero = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 85.04 85.04"
    {...props}
  >
    <rect
      width={52.51}
      height={52.51}
      x={16.26}
      y={16.26}
      className="cls-1"
      rx={2}
    />
    <path
      d="m23.11 23.11 38.82 38.82M50.51 61.93h11.42V50.51M23.11 34.53V23.11h11.42"
      className="cls-1"
    />
  </svg>
)
export default SchermoIntero
