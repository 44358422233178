import React from "react";
import ShareGroceryListBtn from "../components/share/ShareGroceryListBtn";
import DefaultManager from "./Default.manager";
import QrCodeGenerator from "../components/share/QrCodeGenerator";

class PetcareManager extends DefaultManager {
  signboardId;

  constructor(signboardId) {
    super(signboardId);
    this.signboardId = signboardId;
  }

  leftButtonGroceryComponent() {
    if (this.signboardId === 4636) {
      return <QrCodeGenerator />;
    }
    return <ShareGroceryListBtn />;
  }
}

export default PetcareManager;
