/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import "./login.css";
import { connect } from "react-redux";
import { loginJWT, signupJWT } from "../redux/authJWT/action";
import { serverResponse } from "../util/fakeServer";
import { useHistory, useLocation } from "react-router-dom";
import LoadingPages from "./Loading.pages";
import * as services from "../services/resetPassword.services";
import { language } from "../languageProvider/language/language";
import { defaultLanguage } from "../helper/utility";
import { BREAKPOINT, interattivoLogo } from "../helper/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { startLoadingJWT, endLoadingJWT } from "../redux/authJWT/action";
import useCurrentWidth from "../hooks/widthHook";

function FormPrequ(props) {
  const { passwordProtected } = serverResponse.config;
  const [option, setOption] = useState(1);
  const location = useLocation();

  const width = useCurrentWidth();

  const history = useHistory();

  const [errorReset, setErrorReset] = useState(null);
  const [feedMessage, setFeedMessage] = useState(false);
  const [form, setForm] = useState({
    username: "",
    password: ""
  });
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    if (
      location.pathname === "/register" ||
      location.pathname === "/reset-psw"
    ) {
      history.push("/login"); // Usa history.push per cambiare la route
    } else {
      setOption(1);
    }
  }, []);

  const onChange = ({ name, value }) => {
    setForm({ ...form, [name]: value });
  };

  const resetAll = () => {
    setErrorReset(null);
    setFeedMessage(null);
  };
  const handleOnPress = () => {
    setErrorReset(null);
    if (!feedMessage) {
      if (option === 1) {
        props.loginJWT(form, history);
      }
    }
  };

  const handleKeyPress = (e) => {};

  useEffect(() => {
    document.addEventListener("keyup", handleKeyPress);

    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, []);

  if (props.authJWT.loadingProfile) {
    return <LoadingPages />;
  }

  if (
    !serverResponse.config.allow_login ||
    (props.authJWT.isAuthenticated && !props.authJWT.loadingProfile)
  ) {
    if (location.state && location.state.referrer)
      return <Redirect to={`${location.state.referrer}`} />;
    else return <Redirect to={`/leaflet`} />;
  }

  return (
    <div
      className="global-wrapper"
      style={{ flexDirection: width <= BREAKPOINT.lg ? "column" : "row" }}
    >
      <div
        className="main-container"
        style={{
          padding: "10px",
          overflowY: width >= BREAKPOINT.lg ? "hidden" : "scroll",
          paddingBottom: "1rem"
        }}
      >
        <img
          style={{ width: "75px" }}
          className="img-fluid"
          src={serverResponse.config.logo_full}
          alt="logo cliente"
        />
        <header>
          <div
            className={
              "header-headings " +
              (option === 1 ? "sign-in" : option === 2 ? "sign-up" : "forgot")
            }
          >
            <span>{language[defaultLanguage].effettua_login}</span>
          </div>
        </header>

        <form
          className="account-form"
          onSubmit={(evt) => {
            evt.preventDefault();
            handleOnPress();
          }}
        >
          {!props.authJWT.success_signup && (
            <div
              style={{ position: "relative" }}
              className={"account-form-fields sign-in "}
            >
              {/* USERNAME & PASSWORD */}
              <div>
                {!passwordProtected && (
                  <input
                    className="mr-1"
                    style={{ width: option === 2 ? "49%" : "98%" }}
                    value={form.username || ""}
                    id="username"
                    name="username"
                    type="text"
                    placeholder={`${language[defaultLanguage].username}*`}
                    required
                    onChange={(e) => {
                      onChange({ name: "username", value: e.target.value });
                    }}
                  />
                )}
                <input
                  style={{ width: option === 2 ? "49%" : "98%" }}
                  value={form.password || ""}
                  id="password"
                  name="password"
                  type={hidden ? "password" : "text"}
                  placeholder={`${language[defaultLanguage].password}*`}
                  required={option === 1 || option === 2 ? true : false}
                  disabled={option === 3 ? true : false}
                  onChange={(e) => {
                    onChange({ name: "password", value: e.target.value });
                  }}
                />
              </div>
            </div>
          )}

          {!feedMessage && !props.authJWT.feedbackMessage && (
            <button className="btn-submit-form mt-3" type="submit">
              {language[defaultLanguage].login}
              {props.authJWT.loading && (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              {!!errorReset && <div style={{ color: "red" }}>{errorReset}</div>}
            </button>
          )}
          {!!props.authJWT.error && (
            <div style={{ color: "red" }}>
              <p>{props.authJWT.error}</p>
            </div>
          )}
          <div className="d-flex justify-content-center">
            <p
              style={{ color: "#0008", maxWidth: "350px", textAlign: "center" }}
            >
              Per ottenere le credenziali d’accesso inviare una mail a
              <a href="mailto:commerciale@prequitalia.it">
                {" "}
                commerciale@prequitalia.it
              </a>
            </p>
          </div>
          {feedMessage && (
            <p style={{ color: "#0008", width: "320px", textAlign: "center" }}>
              <>
                {feedMessage}
                <FontAwesomeIcon style={{ color: "green" }} icon={faCheck} />
              </>
            </p>
          )}
          {props.authJWT.feedbackMessage && (
            <p style={{ color: "#0008", width: "320px", textAlign: "center" }}>
              <>
                {props.authJWT.feedbackMessage}
                <FontAwesomeIcon style={{ color: "green" }} icon={faCheck} />
              </>
            </p>
          )}
        </form>
      </div>

      <div
        style={{ position: width <= BREAKPOINT.lg ? "static" : "absolute" }}
        className="login_footer_troilo"
      >
        <span
          style={{
            fontSize: "0.7em",
            marginRight: "5px"
          }}
        ></span>
        <img
          style={{
            width: "200px"
          }}
          className="img-fluid"
          src={interattivoLogo}
          alt="logo creator"
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authJWT: state.authJWT
  };
};

export default connect(mapStateToProps, {
  loginJWT,
  signupJWT,
  startLoadingJWT,
  endLoadingJWT
})(FormPrequ);
