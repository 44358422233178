import React, { useCallback, useEffect } from "react";
import HeaderWrapper from "./header.style";
import { iconAppNameNegative } from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter, useHistory } from "react-router-dom";

import { serverResponse } from "../../util/fakeServer";
import { setIndexCarousel, isFromInput } from "../../redux/carousel/action";
import { toggleJWTModal } from "../../redux/authJWT/action";
import { H_COMPONENTS /*, interattivoLogo*/ } from "../../helper/constant";
import {
  defaultLanguage,
  getAnalyticsDevice,
  getAnalyticsUniqueId,
  sendVisit
} from "../../helper/utility";
import { visit } from "../../services/analytics.services";
import {
  isMobileOnly,
  isTablet,
  isIPad13,
  isBrowser as isDesktop
} from "react-device-detect";
import UserAvatar from "../UserAvatar/UserAvatar";
import { styles } from "./head.style";
import { language } from "../../languageProvider/language/language";
import ManagerFactory from "../../managers/ManagerFactory";

const Header = (props) => {
  const manager = ManagerFactory.getManager();
  const history = useHistory();
  const {
    config: { primary_color }
  } = serverResponse;
  useEffect(() => {
    if (
      serverResponse.config.ga_active &&
      sendVisit() &&
      manager.sendAnalitics()
    ) {
      visit({
        id_cliente: serverResponse.config.client_id,
        id_insegna: serverResponse.config.signboard_id,
        id_utente: serverResponse.config.release_id,
        id_volantino: serverResponse.leaflet.id,
        device: getAnalyticsDevice(
          isMobileOnly,
          isTablet || isIPad13,
          isDesktop && !isIPad13
        ),
        unique_id: getAnalyticsUniqueId()
      });
    }
  }, []);

  const handleOnClick = useCallback(
    (value) => history.push({ pathname: value, from: true }),
    [history]
  );

  // const [popoverOpen, setPopoverOpen] = useState(false);
  // const toggle = () => {
  //   setPopoverOpen(!popoverOpen);
  //   if (popoverOpen) {
  //     ReactGA.pageview(
  //       `/${serverResponse.config.client_id}-${serverResponse.leaflet.id}/lista-spesa`
  //     );
  //   }
  // };

  const appLogo = manager.getAppLogo();
  const appLogoSize = manager.getAppLogoSize();
  const headerLink = manager.getLogoHeaderLink();
  const logoMaxSize = manager.getLogoClientMaxSize();
  return (
    <HeaderWrapper {...props}>
      <div className="container-fluid" style={{ height: H_COMPONENTS.header }}>
        <div className="row h-100">
          <div className="col-12 d-flex align-items-center justify-content-between p-md-1 p-2 d-lg-none h-100">
            <>
              {serverResponse.config.logo_full && (
                <img
                  className="h-100"
                  id="logo_client"
                  src={serverResponse.config.logo_full}
                  alt="react-logo"
                  style={{ maxWidth: logoMaxSize, objectFit: "contain" }}
                />
              )}

              <a
                href={headerLink}
                target="_blank"
                rel="noopener noreferrer"
                style={styles.link_decoration}
              >
                {appLogo !== "" && (
                  <img
                    className="h-100"
                    id="logo_header"
                    src={appLogo}
                    alt="react-logo"
                    style={styles.logo_header}
                  />
                )}
                {/* <p style={styles.p_style}>v{process.env.REACT_APP_VERSION}</p> */}
              </a>
              {/*  */}
              {manager.buttonLoginInHeader() &&
                serverResponse.config.allow_login &&
                !props.authJWT.isAuthenticated &&
                !props.authJWT.loadingProfile && (
                  <div type="button" onClick={() => handleOnClick("/login")}>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "13.5px",
                        color: primary_color,
                        cursor: "pointer"
                      }}
                    >
                      ACCEDI
                    </span>
                  </div>
                )}
            </>

            {serverResponse.config.no_link_to_site && (
              <img src={iconAppNameNegative} alt="react-logo" />
            )}
          </div>
          <div className="col-6 d-lg-none d-flex justify-content-center align-items-center"></div>

          <div className="col-12 d-none d-lg-flex justify-content-center">
            <div className="container-fluid p-0 custom_header_container_fluid">
              <div className="row h-100">
                <div className="col-lg-4 d-flex align-items-center">
                  <img src={appLogo} style={{ width: appLogoSize }} alt="" />
                </div>
                <div className="col-lg-8 d-flex justify-content-end align-items-center">
                  {serverResponse.config.allow_login && (
                    <>
                      {props.authJWT.loadingProfile ? (
                        <div
                          className="spinner-grow"
                          style={styles.spinner_grow}
                          role="status"
                        />
                      ) : props.authJWT.isAuthenticated ? (
                        <UserAvatar />
                      ) : (
                        <p
                          onClick={() => {
                            //props.toggleJWTModal();
                            props.history.push("/login");
                          }}
                          className="login_button"
                        >
                          {language[defaultLanguage].login}
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {!serverResponse.config.hide_grocery_list && (
        <GroceryList
          isOpen={popoverOpen}
          toggle={toggle}
          placement={"bottom"}
          target="mobile-grocery"
          isMobile={true}
        />
      )} */}
    </HeaderWrapper>
  );
};
const mapStateToProps = (state) => {
  return {
    groceryList: state.groceryList.list,
    carouselIndex: state.carousel,
    authJWT: state.authJWT
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps, {
    setIndexCarousel,
    isFromInput,
    toggleJWTModal
  })
)(Header);
