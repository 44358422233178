import React from "react";
import { serverResponse } from "../util/fakeServer";
import FormTroiloComponent from "../views/FormTroilo.component";
import DefaultManager from "./Default.manager";

const typeFlyer = {
  leaflet: "logo_interattivo_new.svg",
  menu: "logo_menu_interattivo.svg",
  catalog: "logo_catalog_interattivo.svg"
};

// const iconDemoType = {
//   leaflet: "logo_new.png",
//   menu: "logo_new.png",
//   catalog: "logo_new.png"
// };

// const iconLogoNegativeType = {
//   leaflet: "logo_new.png",
//   menu: "logo_new.png",
//   catalog: "logo_new.png"
// };

const interattivoTypeLogo = typeFlyer[serverResponse.config.type];
// const iconDemoTypeLogo = iconDemoType[serverResponse.config.type];
// const typeLogoNegative = iconLogoNegativeType[serverResponse.config.type];

export const interattivoLogo = require(`assets/images/${interattivoTypeLogo}`);

class TroiloManager extends DefaultManager {
  signboardId;

  constructor(signboardId) {
    super(signboardId);
    this.signboardId = signboardId;
  }

  getSignupForm() {
    return <FormTroiloComponent />;
  }

  getAppLogoSize() {
    return "100px";
  }

  buttonLoginInHeader() {
    return true;
  }

  getLogoClientMaxSize() {
    return "70px";
  }
}

export default TroiloManager;
