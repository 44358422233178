import React, { useState } from "react";
import { serverResponse } from "../../../util/fakeServer";
import BrandModal from "./components/Brand.modal";
import GalleryModal from "./components/Gallery.modal";
import TextModal from "./components/Text.modal";
import TitleModal from "./components/Title.modal";
import UnderPriceImgModal from "./components/UnderPriceImg.modal";
import PriceModal from "./components/Price.modal";
import AddCart from "./components/AddCart.modal";
import ReadMore from "../../common/ReadMore";
import VarietiesModal from "./components/Varieties.modal";
import Related from "./components/Related.modal";
import { connect } from "react-redux";
import useCurrentWidth from "../../../hooks/widthHook";
import { BREAKPOINT } from "../../../helper/constant";
import ManagerFactory from "../../../managers/ManagerFactory";

const manager = ManagerFactory.getManager();

const PlusBody = ({ modalRedux }) => {
  const {
    contentModal: info,
    otherProps: { dynamic_info, dynamic_price }
  } = modalRedux;
  const [varietySelected, setVarietySelected] = useState("");
  const currentWidth = useCurrentWidth();
  const style =
    currentWidth <= BREAKPOINT.lg ? {} : { height: "100%", overflowY: "auto" };

  return (
    <>
      {/* Galleria immagini */}

      <div
        // className="col-8 col-md-12 col-lg-6 m-auto"
        className="col-8 col-md-12 col-lg-6 h-lg-100"
        style={{ position: "relative" }}
      >
        <GalleryModal
          minImg={false}
          dots={true}
          autoplay={true}
          gallery={info.images}
        />
      </div>

      <div style={style} className="col-12 col-lg-6 py-3">
        {/* Aggiunta al carrello */}

        {/* field1 */}
        <div className="row ">
          <div className="col-12 pt-3">
            <TitleModal
              lineHeight={"1.3rem"}
              fontSize={"1.3rem"}
              title={info.field1}
            />
          </div>

          <div className="col-6 col-lg-12 pb-3">
            {/* field2 */}
            <div className="mt-3">
              <TextModal text1={info.field2} varieties={info.varieties} />

              {(!info.brand_logo ||
                info.brand_logo === undefined ||
                info.brand_logo === null) && (
                <TextModal text1={info.field3} text2={info.field4} />
              )}
            </div>

            {/* Brand logo */}
            {info.brand_logo && (
              <BrandModal
                brand={info.brand}
                brand_logo={info.brand_logo}
                line={info.line}
              />
            )}

            {/* Immagine underprice */}
            {serverResponse.config.underPriceImage !== "" &&
              serverResponse.config.underPriceImage !== null &&
              info.underpriced_product && (
                <UnderPriceImgModal
                  underPriceImage={serverResponse.config.underPriceImage}
                />
              )}

            {/* Prezzo */}

            {/* Varietà */}
            {/* {info.varieties.length > 0 && (
              <div className="d-none d-md-block">
                <VarietiesModal
                  varieties={info.varieties}
                  handleChangeVariety={setVarietySelected}
                />
              </div>
            )} */}
          </div>
          {currentWidth > BREAKPOINT.lg && (
            <div className="col-12">
              <PriceModal
                styleContainer={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  position: "relative"
                }}
                dynamic_price={dynamic_price}
                dynamic_info={dynamic_info}
                skul={info.skul}
                colCustom={"col-6"}
                fontSize="2.5rem"
                price_for_kg={info.price_for_kg}
                // styleParagraph={"15px"}
                strike_price={info.strike_price}
                price={info.price}
                offer_price={info.offer_price}
                discount_rate={info.discount_rate}
                price_label={info.price_label}
                no_price={info.no_price}
              />
            </div>
          )}
          {currentWidth <= BREAKPOINT.lg && (
            <div className="col-6 text-right d-flex flex-column align-items-end justify-content-center m-0">
              <PriceModal
                styleContainer={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  position: "relative"
                }}
                dynamic_price={dynamic_price}
                dynamic_info={dynamic_info}
                skul={info.skul}
                colCustom={"col-6"}
                fontSize="1.9rem"
                price_for_kg={info.price_for_kg}
                // styleParagraph={"15px"}
                strike_price={info.strike_price}
                price={info.price}
                offer_price={info.offer_price}
                discount_rate={info.discount_rate}
                price_label={info.price_label}
                no_price={info.no_price}
              />
            </div>
          )}

          <div></div>

          {info.varieties && info.varieties.length > 0 && (
            <div
              className="col-12 col-lg-6 pb-3"
              style={{ fontFamily: "var(--font-family)" }}
            >
              <VarietiesModal
                varieties={info.varieties}
                handleChangeVariety={setVarietySelected}
              />
            </div>
          )}
        </div>

        {!manager.linkToShop() && !(info.no_price && manager.noAddCart()) && (
          <div className="row justify-content-lg-start mt-lg-3 justify-content-around">
            <AddCart
              // colCustom={"col-12"}
              justifyContent={"space-between"}
              setVarietySelected={setVarietySelected}
              varietySelected={varietySelected}
              info={info}
              price={true}
              additionalStyle={{ maxWidth: "max-content", minWidth: "150px" }}
              // componentPrice={
              //   <PriceModal
              //     styleContainer={{
              //       textAlign: "right",
              //       whiteSpace: "nowrap",
              //       padding: 0,
              //       position: "relative"
              //     }}
              //     dynamic_price={dynamic_price}
              //     dynamic_info={dynamic_info}
              //     skul={info.skul}
              //     colCustom={"col-6"}
              //     fontSize="2.3rem"
              //     price_for_kg={info.price_for_kg}
              //     // styleParagraph={"15px"}
              //     strike_price={info.strike_price}
              //     price={info.price}
              //     offer_price={info.offer_price}
              //     discount_rate={info.discount_rate}
              //     price_label={info.price_label}
              //     no_price={info.no_price}
              //   />
              // }
            />
          </div>
        )}

        {manager.linkToShop() && (
          <div className="row justify-content-lg-start mt-lg-3 justify-content-around">
            <a
              href={manager.getShopUrl(info.markers)}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary mx-0 px-0 w-100 text-center text-uppercase"
              style={{
                borderRadius: "30px",
                padding: "8px",
                margin: "0px 0px 0px 15px",
                color: "white",
                fontWeight: "bold",
                fontSize: "0.7rem",
                fontFamily: "var(--font-family)",
                maxWidth: "200px"
              }}
            >
              Vai allo shop
            </a>
          </div>
        )}

        {currentWidth > BREAKPOINT.lg &&
          info.descrizione_estesa !== undefined &&
          info.descrizione_estesa !== "" && (
            <div
              className="row wrapText mtop-3 p-0"
              style={{
                overflowWrap: "break-word",
                fontFamily: "var(--font-family)"
              }}
            >
              <div className="col-12">
                <ReadMore>{info.descrizione_estesa}</ReadMore>
              </div>
            </div>
          )}

        {currentWidth <= BREAKPOINT.lg &&
          info.descrizione_estesa !== undefined &&
          info.descrizione_estesa !== "" && (
            <div className="row">
              <div
                className="col-12 wrapText"
                style={{
                  overflowWrap: "break-word",
                  fontFamily: "var(--font-family)"
                }}
              >
                <ReadMore>{info.descrizione_estesa}</ReadMore>
              </div>
            </div>
          )}
      </div>
      {!!info.related.length > 0 && (
        <div className="col-12">
          {/* Prodotti correlati */}
          <Related related={info.related} />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal
  };
};

export default connect(mapStateToProps)(PlusBody);
