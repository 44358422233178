import React, { Component } from "react";
import {
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  ModalHeader
} from "reactstrap";
import { connect } from "react-redux";
import { serverResponse } from "../../util/fakeServer";
import { styles } from "./ShareGroceryListBtn.style";
import { defaultLanguage, getCustomActions } from "../../helper/utility";
import { language } from "../../languageProvider/language/language";
import { CondividiNew, QrCodeIcon } from "../../assets/images/icone";
import QRCode from "react-qr-code";
import actions from "../../redux/groceryList/action";
class QrCodeGenerator extends Component {
  state = {
    modal: false,
    idParam: Math.random()
      .toString(36)
      .substr(2, 16),
    qrCodeLink: null
  };

  openReactNativeShare = () => {
    const link = `${serverResponse.config.serverPath}myGroceryList/${this.state.idParam}`;
    const title = language[defaultLanguage].visualizza_lista_spesa;
    const str = `${title} ${link}`;
    const obj = { action: "whatsapp", value: str };
    window.ReactNativeWebView.postMessage(JSON.stringify(obj));
  };

  openModal = () => {
    this.exportGroceryList();
    this.setState({ modal: true });
  };

  closeModal = () => {
    this.setState({ modal: false });
  };

  exportGroceryList = () => {
    const tmpProducts = this.props.groceryList.list.map((element) => ({
      ...element,
      image_file: element.images[0] ? element.images[0].image_file : ""
    }));

    const idParam = Math.random()
      .toString(36)
      .substr(2, 16);
    const exportObj = {
      id_param_url: idParam,
      id_campagna: this.props.groceryList.idCampaign,
      prodotti: tmpProducts,
      flyer_url: serverResponse.config.shareFlyerURL,
      pid: this.props.auth.user ? this.props.auth.user.id : null
    };

    getCustomActions().exportList(exportObj);
    const linkGenerated = getCustomActions().getShareListLink(idParam);

    this.setState({
      idParam,
      qrCodeLink: linkGenerated
    });
  };

  closeAndClear = () => {
    this.props.deleteAll();
  };

  render() {
    const { groceryList } = this.props;
    const { list } = groceryList;

    const shareButtonStyle = {
      color: "white",
      fontWeight: "bold",
      display: "flex",
      justifyContent: "space-between",
      background: list.length === 0 ? "grey" : "initial",
      cursor: list.length === 0 ? "default" : "pointer",
      width: "170px"
    };

    const shareLabel = language[defaultLanguage].scarica_lista;

    return (
      <div
        style={shareButtonStyle}
        onClick={list.length === 0 ? undefined : this.openModal}
      >
        <div
          style={styles.shareBtn(serverResponse.config.secondary_color)}
          className="shareButton d-flex align-items-center justify-content-center w-100"
        >
          <QrCodeIcon style={{ width: "35px" }} />
          <span>{shareLabel}</span>
        </div>
        <Modal
          size="lg"
          isOpen={this.state.modal}
          toggle={this.closeModal}
          centered={true}
          style={{ height: "fit-content" }}
        >
          <ModalHeader>
            <h4>
              Inquadra il Qr-code per consultare la lista sul tuo dispositivo
            </h4>
          </ModalHeader>
          <ModalBody style={{ display: "flex", justifyContent: "center" }}>
            <Row
              className="custom-share-container"
              style={{ alignItems: "center" }}
            >
              {this.state.qrCodeLink && (
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={this.state.qrCodeLink}
                  viewBox={`0 0 256 256`}
                />
              )}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.closeModal}>
              Annulla
            </Button>
            <Button color="primary" onClick={this.closeAndClear}>
              Chiudi e Svuota
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  groceryList: state.groceryList,
  auth: state.authJWT
});

function mapDispatchToProps(dispatch) {
  return {
    deleteAll: () => {
      dispatch(actions.deleteAll());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QrCodeGenerator);
