import DefaultManager from "./Default.manager";

class NaputecaManager extends DefaultManager {

    signboardId;

    constructor(signboardId) {
        super(signboardId);
        this.signboardId = signboardId;
    }

    getLogoHeaderLink() {
        return null;
    }
}

export default NaputecaManager;
